.form-task__dependencies__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 84px);

  margin-left: 42px;
  margin-right: 42px;

  & > div {
    border-radius: 4px;
    width: 200px;
    height: 37px;
    border: 1px solid #c4c4c4;
    align-items: center;

    & > button {
      background-color: transparent;
      border: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #292a34;
      padding: 7px 18px;
      width: 100%;
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
    }
    &:hover {
      background: #e6f8fd;
    }
    &:not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }

    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
  }
}

.p__drop-content {
  margin-left: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.form-task__dependencies__btn-active {
  background-color: #e6f8fd !important;
  height: 100%;
}

.doc-list > label:first-child {
  width: 100%;
}

.form-task__dependencies__btns_disable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 84px);

  margin-left: 42px;
  margin-right: 42px;

  & > div {
    border-radius: 4px;
    width: 200px;
    height: 37px;
    border: 1px solid #c4c4c4;
    align-items: center;

    & > button {
      background-color: transparent;
      border: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #292a34;
      padding: 7px 18px;
      width: 100%;
    }
    &:hover {
      background: #292a3447;
    }
    &:not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }

    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
  }
}

.deleteArrow select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-appearance: none;
}

.deleteArrow textarea {
  resize: none;
}

.blockOfFormAutor {
  // border: 1px solid grey;
  padding-bottom: 15px;
  pointer-events: none;
  margin-bottom: 10px;
}

.input-form-create-bp {
  line-height: 1.3em;
  resize: none;
}

.oneRowTwoElem {
  align-items: center;
  & > div:first-child {
    width: 294px;
  }
  & > div:last-child {
    margin-left: 96px;
  }
}

.blockChange {
  cursor: default;
  pointer-events: none;
}
