.comments_div {
  pointer-events: none;
}

.step {
  margin: 20px 0 10px 10px;
}

.span {
  margin-left: 7px;
}

// .text{
//     position: relative;
//     top: 20px;
//     left: 145px;
//     color: gray;
//     font-size: 15px;
// }
