.hover {
  &:hover {
    color: #01B0E9;
} 
 
}

.hover-disable{
  color: gray;
}


.bp-item-menu {
  position: absolute;
  padding: 29px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 1;
  right: 5px;
  top: 44px;
  width: 260px;

  & li {
    color: #292a34;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
