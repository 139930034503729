.checklist-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  & > div {
    display: flex;
    padding: 5px 8px;
    width: 100%;
    align-items: center;
    & > span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }
}

.checklist-main input[type="checkbox"] {
  align-items: center;
  width: 17px;
  height: 17px;
  border: 2px solid black;
  border-radius: 0 0 0 0;
  cursor: pointer;
}
.cl-input {
  margin: 0;
  cursor: pointer;
  &:focus {
    border: 1px solid #d8d8d8;
  }
}
.delete-cl-button {
  color: red;
  cursor: pointer;
}
.cl-count-span {
  padding: 0 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.checklist-text {
  color: #01b0e9;
  cursor: pointer;
}
.cl-head {
  padding-right: 5px;
}
.cl-foot {
  padding-left: 5px;
}

.cl-row {
  padding: 0 8px;
  align-items: center;
}
.loading-cl {
  height: 4px;
  width: 90%;
  border: 1px solid #01b0e9;
  margin-left: 10px;
  border-radius: 2px 2px 2px 2px;
}
.blockChange {
  cursor: default;
  pointer-events: none;
}
